import React, { useEffect } from "react";
import { connect } from "react-redux";
import { graphql } from 'gatsby'
import { trackingGA } from '@merchx-v2/helpers'
import { useSiteMetadata } from 'hooks/use-site-metadata'
import { Footer, Header, SEO } from "components";
import underConstructionImg from "assets/underConstruction.svg";
import {
  actionCreators as actionHomeCreators,
  selectMenu,
} from "state/HomePage/reducer";
import * as styles from "./ConstructionPage.module.scss";

const ConstructionPage = ({ menu, resetMenuFilters, setMenuCollection, data }) => {
  const { shared } = useSiteMetadata()

  const supportEmail = shared.SUPPORT_EMAIL;

  useEffect(() => {
    window.scrollTo(0, 0);
    trackingGA()
  }, []);

  const handleCollectionClicked = (collection) => {
    setMenuCollection(collection);
  };

  const handleReset = () => {
    resetMenuFilters();
  };

  const { SEO_TITLE, SEO_DESCRIPTION, SEO_KEYWORDS } = data?.markdownRemark?.frontmatter || {}

  return (
    <div className={styles.construction}>
      <SEO
        title={SEO_TITLE}
        description={SEO_DESCRIPTION}
        keywords={SEO_KEYWORDS}
      />
      <div className={styles.content}>
        <Header
          menu={menu}
          onCollectionClicked={handleCollectionClicked}
          onResetCollection={handleReset}
        />
        <div className={styles.container}>
          <img src={underConstructionImg} alt="" />
          <div className={styles.title}>Coming Soon!</div>

          <div className={styles.description}>
            We will be back soon with our new awesome page!
          </div>
          <div className={styles.description}>
            For any questions&nbsp;
            <a
              className={styles.descriptionLink}
              href={`mailto:${supportEmail}`}
            >
              {supportEmail}
            </a>
          </div>
        </div>
      </div>

      <div className={styles.footerContainer}>
        <Footer handleReset={handleReset} />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  menu: selectMenu(state),
});
const mapDispatchToProps = {
  resetMenuFilters: actionHomeCreators.resetMenuFilters,
  setMenuCollection: actionHomeCreators.setMenuCollection,
};

export default connect(mapStateToProps, mapDispatchToProps)(ConstructionPage);

export const query = graphql`
  query UnderconstructionPage {
    markdownRemark(fields: {slug: {eq: "/underconstruction/"}}) {
      frontmatter {
        SEO_TITLE
        SEO_DESCRIPTION
        SEO_KEYWORDS
      }
    }
  }
`
